import { inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

const METRICA_ID = 40985284;

export interface CustomWindow extends Window {
	ym: (id: number, type: string, name: string) => void;
}

export const reachGoalMetrica = () => {
	const _window = inject(WINDOW);

	return (goalName: string) => {
		const ymFunction = (_window as unknown as CustomWindow).ym;
		if (ymFunction) ymFunction(METRICA_ID, 'reachGoal', goalName);
	};
};
